export const column = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode SPSI',
    slots: { customRender: 'spsi_code' },
  },
  {
    title: 'Tanggal Transaksi',
    slots: { customRender: 'tanggal' },

  },
  {
    title: 'Nama Toko',
    slots: { customRender: 'customer_name' },

  },
  {
    title: 'Alamat Toko',
    slots: { customRender: 'customer_address' },
  },
  {
    title: 'Kabupaten',
    slots: { customRender: 'customer_kabupaten' },

  },
  {
    title: 'Provinsi',
    slots: { customRender: 'customer_propinsi' },

  },
  {
    title: 'Regional',
    slots: { customRender: 'customer_regional' },

  },
  {
    title: 'Area',
    slots: { customRender: 'customer_area' },

  },
  {
    title: 'Brands',
    slots: { customRender: 'brand_name' },

  },
  {
    title: 'Kode Produk',
    slots: { customRender: 'product_code' },
  },

  {
    title: 'Harga',
    slots: { customRender: 'price' },

  },

  {
    title: 'Zak Qty',
    slots: { customRender: 'quantity_zak' },

  },

  {
    title: 'UOM',
    slots: { customRender: 'uom1' },

  },

  {
    title: 'TON Qty',
    dataIndex:"quantity_ton",
  },
  {
    title: 'UOM 2',
    dataIndex:"uom2",
  },
  {
    title: 'No Transaksi',
    dataIndex:"transaksi_code",

  },
  {
    title: 'Kode Distributor',
    dataIndex:"distributor_code",

  },
  {
    title: 'Nama Distributor',
    dataIndex:"distributor_name",

  },
  {
    title: 'Kode Gudang',
    dataIndex:"warehouse_code",

  },
  {
    title: 'Nama Gudang',
    dataIndex:"warehouse_name",

  },

  {
    title: 'Provinsi Gudang',
    dataIndex:"warehouse_propinsi",

  },
  {
    title: 'Area Gudang',
    dataIndex:"warehouse_area",

  },
  {
    title: 'Kabupaten Gudang',
    dataIndex:"warehouse_kabupaten",

  },
  {
    title: 'Tipe Customer',
    dataIndex:"type_name",

  },
  {
    title: 'Cluster',
    dataIndex:"cluster_name",

  },
  {
    title: 'SSM',
    dataIndex:"user_ssm",

  },
  {
    title: 'ASM',
    dataIndex:"user_sm",

  },
  {
    title: 'TSO',
    dataIndex:"user_am",

  },
  {
    title: 'Source System',
    dataIndex:"erp_name",

  },
  {
    title: 'Created At Dist',
    dataIndex:"created_at_dist",

  },
]
